import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useProfileStore } from '../stores';
import { Redirect } from 'react-router';
import { BASE_URL, appDisplayName } from '../const';
import useSWR from 'swr';
import { useCallback, useMemo, useState } from 'react';
import { downloadOutline } from 'ionicons/icons';

export function GenerateRecapPage(): JSX.Element {
  const athleteId = useProfileStore((state) => state.profile?.id);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appDisplayName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{appDisplayName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {athleteId ? <GenerateRecap athleteId={athleteId} /> : <Redirect to="/login" />}
      </IonContent>
    </IonPage>
  );
}

interface RecapData {
  success: boolean;
  code: number;
  message: string;
  done?: boolean;
  processing_started?: boolean;
  url?: string;
  queueRecapPosition?: number;
}

interface GenerateRecapProps {
  athleteId: number;
}
function GenerateRecap(props: GenerateRecapProps): JSX.Element {
  const { athleteId } = props;

  const token = useProfileStore.getState().token;
  const url = `${BASE_URL}/get-recap-data/${athleteId}/${token}`;

  const { data, error, isValidating, mutate } = useSWR<RecapData>(
    url,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  const [disabledButton, setDisabledButton] = useState(false);
  const handleClickGenerate = useCallback(async () => {
    setDisabledButton(true);

    await fetch(`${BASE_URL}/recap/${athleteId}/${token}`, { mode: 'cors' });

    mutate();

    setTimeout(() => {
      mutate();
    }, 5000);
  }, [athleteId, mutate, token]);

  const fileName = useMemo(() => {
    if (data?.url){
      const url = new URL(data.url);
      return url.pathname.split('/').pop();
    }
  }, [data?.url]);

  if (!data) {
    return <IonSpinner></IonSpinner>;
  }

  return (
    <IonContent>
      {data && data.code === 404 && (
        <IonButton onClick={handleClickGenerate} disabled={disabledButton}>
          Generate your 2023 Recap
        </IonButton>
      )}

      {data && data.url && (
        <IonCard>
          <IonCardContent>
            <video
              autoPlay
              controls
              src={data.url}
              loop
              style={{
                width: '100%',
                height: 'auto',
              }}
            />

            <IonButton expand="block" href={data.url} target="_blank" download={fileName}>
              <IonIcon slot="end" icon={downloadOutline}></IonIcon>
              Download
            </IonButton>
          </IonCardContent>
        </IonCard>
      )}

      {data && data.code === 200 && data.processing_started && !data.done && (
        <IonCard>
          <IonCardContent>
            <IonSpinner></IonSpinner>
            <p>Your recap is being generated...</p>
          </IonCardContent>
        </IonCard>
      )}

      {data && data.code === 200 && !data.processing_started && (
        <IonCard>
          <IonCardContent>
            <p>You are in the queue for processing (position {(data.queueRecapPosition ?? 0) + 1})</p>
          </IonCardContent>
        </IonCard>
      )}
    </IonContent>
  );
}

export default GenerateRecapPage;
