export function dateToWeekNumber(date: Date): number {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  return Math.ceil(((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
}

export function formatDuration(duration: number): string {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;

  return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`;
}

export function meterPerSecondToMinAndSecPerkm(velocity: number): string {
  if (velocity < 0.3) {
    return '--:--';
  }

  const min = Math.floor(1000 / velocity / 60);
  const sec = Math.floor((1000 / velocity) % 60);

  return `${min.toFixed(0)}:${sec < 10 ? '0' : ''}${sec.toFixed(0)}`;
}