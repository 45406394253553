import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getStravaProfile } from '.';
import { useProfileStore } from './stores';

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();

  const handleAppUrlOpen = useCallback(
    async (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('.com').pop();

      console.log(`appUrlOpen event (slug: ${slug})`);

      const isSlugSignedIn = slug?.startsWith('/signed-in');
      const isSlugAuthStravaCallback = slug?.startsWith('/auth/strava/callback');

      if (isPlatform('ios') && isSlugAuthStravaCallback) {
        console.log('ios detected, sending a fetch request');
        try {
          const reqRes = await fetch(event.url, { mode: 'cors' }).then((res) => res.json());
          console.log('reqRes', reqRes);
        } catch (err) {
          console.log('err', err);
        }
      }

      // if (slug) {
      //   history.push(slug);
      // }
      if (isSlugSignedIn || isSlugAuthStravaCallback) {
        const token = new URL(event.url).searchParams.get('code');
        if (!token) {
          throw new Error('No token found in URL');
        }

        console.log(`appUrlOpen event (token: ${token}), url: ${event.url}`);

        useProfileStore.getState().setToken(token);

        getStravaProfile();

        history.push('/');

        Browser.close();
      }
      // If no match, do nothing - let regular routing
      // logic take over
    },
    [history]
  );

  useEffect(() => {
    // https://github.com/ionic-team/capacitor/issues/3255#issuecomment-775593971
    Capacitor.Plugins.App.addListener('appUrlOpen', handleAppUrlOpen);

    App.addListener('appUrlOpen', handleAppUrlOpen);
  }, [handleAppUrlOpen]);

  return null;
};

export default AppUrlListener;
