import { Browser } from '@capacitor/browser';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router';
import { updateStoreProfile } from '..';
import StravaBtn from '../assets/btn_strava_connectwith_orange.svg';
import { ZonesBarChart } from '../components/ZonesBarCharts';
import { appDisplayName, serverUrl } from '../const';
import { useProfileStore } from '../stores';
import { dateToWeekNumber } from '../utils';
import './Tab1.css';

function getWeeksAgoDate(weeksAgo: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - weeksAgo * 7);

  return date;
}

export function LoginPage(): JSX.Element {
  const athleteId = useProfileStore((state) => state.profile?.id);
  const token = useProfileStore((state) => state.token);

  const code = new URL(window.location.href).searchParams.get('code');
  if (!token && code) {
    useProfileStore.setState((state) => ({
      ...state,
      token: code,
    }));
  }

  useEffect(() => {
    let intervalID: NodeJS.Timeout;
    if (!athleteId) {
      updateStoreProfile();

      intervalID = setInterval(() => {
        updateStoreProfile();
      }, 5000);
    }

    return () => clearInterval(intervalID);
  }, [athleteId]);

  useEffect(() => {
    const ionTabBarEl = document.querySelector('ion-tab-bar');
    if (ionTabBarEl) {
      ionTabBarEl.style.display = 'none';
    }

    return () => {
      if (ionTabBarEl) ionTabBarEl.style.display = 'flex';
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appDisplayName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{appDisplayName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {athleteId ? <Redirect to="/profile" /> : <StravaBtnFullPage />}
      </IonContent>
    </IonPage>
  );
}

const Tab1: React.FC = () => {
  const athleteId = useProfileStore((state) => state.profile?.id);

  const yearWeeks = useMemo(() => {
    const weeksAgo = [1, 2, 3, 4, 5, 6];
    const dateWeeksAgo = weeksAgo.map((weeksAgo) => getWeeksAgoDate(weeksAgo));
    const weeksNbs = dateWeeksAgo.map((date) => `${date.getFullYear()}/${dateToWeekNumber(date)}`);

    return weeksNbs;
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appDisplayName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{appDisplayName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* <HRSlider/> */}

        {/* <Suspense fallback={<IonSpinner />}> */}
        {athleteId ? (
          yearWeeks.map((yearWeek) => {
            const split = yearWeek.split('/');
            const year = parseInt(split[0], 10);
            const weekNb = parseInt(split[1], 10);

            return athleteId ? (
              <ZonesBarChart key={yearWeek} athleteId={athleteId} weekNb={weekNb} year={year} />
            ) : (
              <></>
            );
          })
        ) : (
          <></>
        )}
        {/* </Suspense> */}
      </IonContent>
    </IonPage>
  );
};

interface SignInStravaBtnProps {
  onLoggedIn?: () => void;
}
function SignInStravaBtn(props: SignInStravaBtnProps): JSX.Element {
  const originUrl = window.location.href;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const isWeb = isPlatform('desktop') || isPlatform('mobileweb') || true;

      const giveOriginUrl = isWeb;

      const url = `${serverUrl}/auth/strava${giveOriginUrl ? `?originUrl=${originUrl}` : ''}`;

      console.log('url', url);


      if (isWeb) {
        // window.open(url, '_blank');
        document.location.href = url;

        return;
      }

      console.log(`Opening ${url}...`);

      Browser.open({
        url,

        // headers: {
        //   'User-Agent': window.navigator.userAgent.replace('; wv)', ')'),
        // },
      });

      console.log(`Browser.open(${url}) done.`);
    },
    [originUrl]
  );

  return (
    <button
      style={{
        margin: 'auto',
        width: '100%',
        textAlign: 'center',
        background: 'none',
      }}
      onClick={handleClick}
    >
      <img
        src={StravaBtn}
        alt="Strava connection"
        style={{
          margin: 'auto',
        }}
      />
    </button>
  );
}

export function StravaBtnFullPage(): JSX.Element {
  return (
    <div
      style={{
        marginTop: '20%',
      }}
    >
      <SignInStravaBtn />
    </div>
  );
}

export default Tab1;
