import { IonButton, IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useProfileStore, useUserStore } from "../stores";
import { useEffect, useRef, useState } from "react";
import { serverUrl } from "../const";
import { Redirect } from "react-router";

export function FillInEmailPage(): JSX.Element {
    const athleteId = useProfileStore((state) => state.profile?.id);
    const email = useUserStore((state) => state.user?.email);

    const [isValid, setIsValid] = useState<boolean>();

    const validateEmail = (email: string) => {
        return email.match(
          /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        );
      };
    
      const validate = (ev: Event) => {
        const value = (ev.target as HTMLInputElement).value;
    
        setIsValid(undefined);
    
        if (value === '') return;
    
        validateEmail(value) !== null ? setIsValid(true) : setIsValid(false);
      };

      async function updateEmail():Promise<string|undefined>{
        // update the email from the db
        const token = useProfileStore.getState().token;
        console.log(`updateEmail, token: ${token}`);
      
        if (!token) {
          console.log('No token found aborting');
      
          return;
        }

        const url = `${serverUrl}/api/email/${athleteId}${token ? `/${token}` : ''}`;
        try {
          const res = await fetch(url, { method: 'GET', credentials: 'include', mode: 'cors' });
          const resTxt = await res.text();
      
          console.log(`updateEmail, response: ${resTxt}`);
      
          const json = JSON.parse(resTxt);

          const email = json?.email;
            useUserStore.getState().setEmail(email);
      
          return json?.email;
        } catch (err) {
          console.error(`updateEmail() error: ${err}, url: ${url}`);
          throw err;
        }
    }

    useEffect(() => {
        updateEmail();
      }, []);

      const emailInputRef = useRef<HTMLIonInputElement>(null);

      async function submitEmail():Promise<void>{
        // update the email from the db
        const token = useProfileStore.getState().token;
        console.log(`submitEmail, token: ${token}`);
      
        if (!token) {
          console.log('No token found aborting');
      
          return;
        }

        let newEmail = emailInputRef.current?.value;
        if (!newEmail) {
          console.log('No email found aborting');
      
          return;
        }

        newEmail = newEmail.toString().trim();

        if (!validateEmail(newEmail)) {
          console.log('Invalid email found aborting');
      
          return;
        }

        const data = new FormData();
        data.append('email', newEmail);

        const url = `${serverUrl}/api/email/${athleteId}${token ? `/${token}` : ''}`;
        try {
          const res = await fetch(url, { method: 'POST', 
            body:data
        });
          const resTxt = await res.text();
      
          console.log(`submitEmail, response: ${resTxt}`);
      
          const json = JSON.parse(resTxt);

          const email = json?.email;
            useUserStore.getState().setEmail(email);
      
          return json?.email;
        } catch (err) {
          console.error(`submitEmail() error: ${err}, url: ${url}`);
          throw err;
        }
    }

      

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Email</IonTitle>
            </IonToolbar>
        </IonHeader>
        {
            email && <Redirect to="/profile" />
        }
        <IonContent fullscreen style={{
            margin: '10px'
        }}>
            <h1>Just one last question</h1>
            <IonInput ref={emailInputRef} onIonInput={(event) => validate(event)} aria-label="Email" label="Email"  type="email" placeholder="my-super@email.com" labelPlacement="stacked" />
            <IonButton
                expand="block"
                disabled={!isValid}
                onClick={submitEmail}
                >
                    OK
                </IonButton>
        </IonContent>
    </IonPage>
}