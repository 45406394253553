import { IonButton, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Redirect } from 'react-router';
import { HRSlider } from '../components/HRSlider';
import { useProfileStore, useUserStore } from '../stores';
import { logoInstagram } from 'ionicons/icons';

export function ProfilePage(): JSX.Element {
  const athleteId = useProfileStore((state) => state.profile?.id);
  const displayName = useProfileStore((state) => state.profile?.displayName);
  const email = useUserStore((state) => state.user?.email);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{displayName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{displayName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {!athleteId ? <Redirect to="/login" /> : null}
        {
          athleteId && !email ? (
            <Redirect to="/fill-in-email" />
          ) : null
        }

        <HRSlider />

        <GenerateRecapBtn />

        <InstagramLinkBtn />
      </IonContent>
    </IonPage>
  );
}

function GenerateRecapBtn(): JSX.Element {
  return (
    <IonButton
      href="/recap"
      expand="block"
      style={{
        textTransform: 'none',
        marginTop: '1rem',
      }}
    >
      2023 in Sport
    </IonButton>
  );
}

function InstagramLinkBtn(): JSX.Element {
  return (
    <IonButton
      href="https://www.instagram.com/motionmaniaapp"
      color={'light'}
      expand="block"
      style={{
        textTransform: 'none',
        marginTop: '1rem',
      }}
      target="_blank"
    >
      <IonIcon slot="start" icon={logoInstagram} />
      @motionmaniaapp
    </IonButton>
  );
}
