export const appDisplayName = 'MotionMania';

export const serverUrl = `${process.env.REACT_APP_SERVER_PROTOCOL ?? 'http://'}${
  process.env.REACT_APP_SERVER_URL ?? 'localhost'
}${
  process.env.REACT_APP_SERVER_PORT !== '80' && process.env.REACT_APP_SERVER_PORT !== '443'
    ? `:${process.env.REACT_APP_SERVER_PORT ?? '3000'}`
    : ''
}`;

export const BASE_URL = `${serverUrl}/api`;
