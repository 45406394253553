import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { AlarmCheck, Gauge, HeartCrack, HeartPulse, Ruler, Timer } from 'lucide-react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import useSWR from 'swr';
import { BASE_URL } from '../const';
import { Activity } from '../prisma';
import { formatDuration, meterPerSecondToMinAndSecPerkm } from '../utils';
import { useProfileStore } from '../stores';

interface ActivityPageProps
  extends RouteComponentProps<{
    activityId: string;
  }> {}

export function ActivityPage(props: ActivityPageProps): JSX.Element {
  // const athleteId = useProfileStore((state) => state.profile?.id);

  const { activityId } = props.match.params;

  const token = useProfileStore((state) => state.token);
  const url = `${BASE_URL}/activity/${activityId}/${token}`;

  const { data, error } = useSWR<Partial<Activity>>(
    url,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  useEffect(() => {
    if (data) console.log('ActivityPage, data', data);
  }, [data]);

  useEffect(() => {
    if (error) console.error('ActivityPage, error', error);
  }, [error]);

  if (!data) {
    return <IonSpinner></IonSpinner>;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{data.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{data.name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{data.name}</IonCardTitle>
            <IonCardSubtitle>
              {new Date(data.start_date ?? 0)?.toLocaleDateString()}{' '}
              {new Date(data.start_date ?? 0)?.toLocaleTimeString()}
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{((data.distance ?? 0) / 1000).toFixed(2)} km</IonCardTitle>
                <IonCardSubtitle
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  <Ruler /> Distance
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{formatDuration(data.elapsed_time ?? 0)}</IonCardTitle>
                <IonCardSubtitle
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  <Timer /> Duration
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{formatDuration(data.moving_time ?? 0)}</IonCardTitle>
                <IonCardSubtitle
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  <AlarmCheck /> Duration (moving)
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{data.average_heartrate ?? 0} bpm</IonCardTitle>
                <IonCardSubtitle
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  <HeartPulse /> Average heartrate
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{data.max_heartrate ?? 0} bpm</IonCardTitle>
                <IonCardSubtitle
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  <HeartCrack /> Max heartrate
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{meterPerSecondToMinAndSecPerkm(data.average_speed ?? 0)} min/km</IonCardTitle>
                <IonCardSubtitle
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  <Gauge /> Average pace
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonCardContent>

          {/* <IonButton fill="clear">Action 1</IonButton> */}
          <IonButton fill="solid" expand="block" routerLink={`/video-editor/${activityId}`}>
            Generate video
          </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
}
