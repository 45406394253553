import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import useSWR, { useSWRConfig } from 'swr';
import { appDisplayName, BASE_URL } from '../const';
import { Activity } from '../prisma';
import { useProfileStore } from '../stores';

export function ListActivitiesPage(): JSX.Element {
  const athleteId = useProfileStore((state) => state.profile?.id);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appDisplayName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{appDisplayName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {athleteId ? <ListActivites athleteId={athleteId} /> : <Redirect to="/login" />}
      </IonContent>
    </IonPage>
  );
}

interface ListActivitesProps {
  athleteId: number;
}
export function ListActivites(props: ListActivitesProps): JSX.Element {
  const { athleteId } = props;

  const token = useProfileStore.getState().token;
  const url = `${BASE_URL}/get-activities/${athleteId}/${token}`;

  console.log('url', url);

  const { mutate } = useSWRConfig();
  const { data, error, isValidating } = useSWR<Partial<Activity>[]>(
    url,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  useEffect(() => {
    if (data) console.log('ListActivites, data', data);
  }, [data]);

  useEffect(() => {
    if (error) console.error('ListActivites, error', error);
  }, [error]);

  const [refreshingDetail, setRefreshingDetail] = useState<RefresherEventDetail | null>(null);
  const handleRefresh = useCallback(
    (e: CustomEvent<RefresherEventDetail>) => {
      mutate(url);

      setRefreshingDetail(e.detail);
    },
    [mutate, url]
  );

  useEffect(() => {
    if (refreshingDetail && !isValidating) {
      refreshingDetail.complete();
      setRefreshingDetail(null);
    }
  }, [isValidating, refreshingDetail]);

  const handleClickRefreshBtn = useCallback(() => {
    mutate(url);
  }, [mutate, url]);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      <IonProgressBar type="indeterminate" hidden={!isValidating} style={{ marginBottom: '-4px' }}></IonProgressBar>

      <div>
        <h1>
          Activities{' '}
          <IonButton
            style={{ marginLeft: '8px', marginRight: '8px', float: 'right' }}
            onClick={handleClickRefreshBtn}
            size="small"
          >
            <IonIcon slot="icon-only" icon={refreshOutline}></IonIcon>
          </IonButton>
        </h1>
        {data ? (
          data.map((activity) => (
            <IonCard key={activity.id?.toString()} routerLink={`/activity/${activity.id?.toString()}`}>
              <IonCardHeader>
                <IonCardTitle>{activity.name}</IonCardTitle>
                <IonCardSubtitle>
                  {'start_date' in activity
                    ? `${new Date(activity.start_date ?? 0).toLocaleDateString()} ${new Date(
                        activity.start_date ?? 0
                      ).toLocaleTimeString()}`
                    : ''}
                </IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>{((activity.distance ?? 0) / 1000).toFixed(2)} km</IonCardContent>
            </IonCard>
          ))
        ) : (
          <IonSpinner></IonSpinner>
        )}
      </div>
    </>
  );
}
