import { IonItem, IonList, IonRange } from '@ionic/react';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon } from '@ionic/react';
import { RangeValue } from '@ionic/core';
import { HrMaxAPIResponse, HRZonesSummary } from '@amatewasu/strava-stats-common';
import { heart } from 'ionicons/icons';
import { useProfileStore, useUserStore } from '../stores';
import { serverUrl } from '../const';

interface HRSliderProps {}
export function HRSlider({}: HRSliderProps): JSX.Element {
  const [percentage, setPercentage] = useState<RangeValue>(100);

  const athleteId = useProfileStore((state) => state.profile?.id);
  const [maxHR, setMaxHR] = useUserStore((state) => [state.user?.maxHR, state.setMaxHR]);
  const { data, error } = useSWR<HrMaxAPIResponse>(
    athleteId ? `${serverUrl}/api/hrmax/${athleteId}` : null,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (error) console.error(`HRSlider, error while fetching data: ${error}`);
  }, [error]);

  useEffect(() => {
    if (data) {
      setMaxHR(data.max_heartrate);
    }
  }, [data, setMaxHR]);

  if (!maxHR) return <></>;
  if (!athleteId) return <></>;

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <>
            {percentage}% = {((maxHR * (percentage as number)) / 100).toFixed(0)} bpm
          </>
        </IonCardTitle>
        <IonCardSubtitle>Max HR: {maxHR} bpm</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRange onIonChange={(e) => setPercentage(e.detail.value)} min={50} max={100} value={percentage}>
          <IonIcon slot="start" icon={heart} size="small"></IonIcon>
          <IonIcon slot="end" icon={heart}></IonIcon>
        </IonRange>
      </IonCardContent>
      <IonCardContent>
        <IonList>
          <IonItem>Zone 1: [0, {(maxHR * 0.65).toFixed(0)}] bpm</IonItem>
          <IonItem>
            Zone 2: [{(maxHR * 0.65).toFixed(0)}, {(maxHR * 0.75).toFixed(0)}] bpm
          </IonItem>
          <IonItem>
            Zone 3: [{(maxHR * 0.75).toFixed(0)}, {(maxHR * 0.85).toFixed(0)}] bpm
          </IonItem>
          <IonItem>
            Zone 4: [{(maxHR * 0.85).toFixed(0)}, {(maxHR * 0.95).toFixed(0)}] bpm
          </IonItem>
          <IonItem>
            Zone 5: [{(maxHR * 0.95).toFixed(0)}, {(maxHR * 1).toFixed(0)}] bpm
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
}
