import { StravaProfilePassport } from '@amatewasu/strava-stats-common';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { serverUrl } from './const';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useProfileStore } from './stores';

Sentry.init({
  dsn: 'https://a50d2386b3bb439d9814d925e315ddfc@o4505116225110016.ingest.sentry.io/4505116231598080',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
if (!container) {
  throw new Error('No root element');
}

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export async function getStravaProfile(): Promise<StravaProfilePassport | undefined> {
  const token = useProfileStore.getState().token;
  console.log(`getStravaProfile, token: ${token}`);

  if (!token) {
    console.log('No token found aborting');

    return;
  }

  const url = `${serverUrl}/api/strava-profile${token ? `/${token}` : ''}`;
  try {
    const res = await fetch(url, { method: 'GET', credentials: 'include', mode: 'cors' });
    const resTxt = await res.text();

    console.log(`getStravaProfile, response: ${resTxt}`);

    const json = JSON.parse(resTxt);

    return json;
  } catch (err) {
    console.error(`getStravaProfile() error: ${err}, url: ${url}`);
    throw err;
  }
}

export async function updateStoreProfile(): Promise<void> {
  const stravaProfile = await getStravaProfile();
  if (!stravaProfile) return;
  useProfileStore.getState().setProfile(stravaProfile);
}

updateStoreProfile();