import { IonApp, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Activity as ActivityIcon, User as UserIcon } from 'lucide-react';
import { Redirect, Route } from 'react-router-dom';
import AppUrlListener from './AppUrlListener';
import { ActivityPage } from './pages/activity';
import { ListActivitiesPage } from './pages/list-activities';
import { ProfilePage } from './pages/profile';
import Tab1, { LoginPage } from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import { VideoEditorPage } from './pages/video-editor';
import './theme/variables.css';
import GenerateRecapPage from './pages/recap';
import { FillInEmailPage } from './pages/fill-in-email';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <AppUrlListener></AppUrlListener>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/tab1">
            <Tab1 />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/fill-in-email">
            <FillInEmailPage />
          </Route>
          <Route exact path="/tab2">
            <Tab2 />
          </Route>
          <Route path="/tab3">
            <Tab3 />
          </Route>
          <Route exact path="/list-activities">
            <ListActivitiesPage />
          </Route>
          <Route exact path="/profile">
            <ProfilePage />
          </Route>
          <Route exact path="/recap">
            <GenerateRecapPage />
          </Route>
          <Route exact path="/activity/:activityId" component={ActivityPage}></Route>
          <Route
            exact
            path="/video-editor/:activityId"
            // use the render prop to pass the key to the component and refresh the component when the activityid changes
            render={(props) => (
              <VideoEditorPage
                key={props.match.params.activityId}
                history={props.history}
                location={props.location}
                match={props.match}
              />
            )}
          ></Route>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          {/* <IonTabButton tab="tab1" href="/tab1">
            <IonIcon icon={calendar} />
            <IonLabel>Weekly</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={calculator} />
            <IonLabel>Calculator</IonLabel>
          </IonTabButton> */}
          <IonTabButton tab="Profile" href="/profile">
            <UserIcon />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
          <IonTabButton tab="Activities" href="/list-activities">
            <ActivityIcon />
            <IonLabel>Activities</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
