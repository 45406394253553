import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonProgressBar,
  IonReorder,
  IonReorderGroup,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
  ItemReorderEventDetail,
} from '@ionic/react';
// import MediaInfo, { Result } from 'mediainfo.js';
import { downloadOutline } from 'ionicons/icons';
import { View } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import useSWR from 'swr';
import { BASE_URL } from '../const';
import { Activity } from '../prisma';
import { useProfileStore } from '../stores';

interface VideoEditorPageProps
  extends RouteComponentProps<{
    activityId: string;
  }> {}

interface AvailableActivityStreams {
  time: boolean;
  distance: boolean;
  altitude: boolean;
  velocity_smooth: boolean;
  velocity_split: boolean;
  heartrate: boolean;
  cadence: boolean;
  watts: boolean;
  temp: boolean;
  moving: boolean;
  grade_smooth: boolean;
}

export function VideoEditorPage(props: VideoEditorPageProps): JSX.Element {
  const athleteId = useProfileStore((state) => state.profile?.id);

  const { activityId } = props.match.params;

  const token = useProfileStore((state) => state.token);
  const url = `${BASE_URL}/activity-full/${activityId}/${token}`;
  const urlAvailableActivityStreams = `${BASE_URL}/get-available-activity-streams/${activityId}/${token}`;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, error } = useSWR<Partial<Activity>>(
    url,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  const { data: availableActivityStreams, error: availableActivityStreamsError } = useSWR<AvailableActivityStreams>(
    urlAvailableActivityStreams,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (data) console.log('VideoEditoPage, data', data);
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (error) console.error('VideoEditorPage, error', error);
  }, [error]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (availableActivityStreams) console.log('VideoEditorPage, availableActivityStreams', availableActivityStreams);
  }, [availableActivityStreams]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (availableActivityStreamsError)
      console.error('VideoEditorPage, availableActivityStreamsError', availableActivityStreamsError);
  }, [availableActivityStreamsError]);

  const [videoFile, setVideoFile] = useState<File | null>(null);
  // const [videoMeatadata, setVideoMetadata] = useState<Result | null>(null);

  const handleInputBtnClick = useCallback(async () => {
    const opts = {
      multiple: false,
      types: [
        {
          description: 'Video',
          accept: {
            'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm', '.wmv', '.flv', '.m4v', '.mpeg', '.mpg', '.m4v'],
          },
        },
      ],
    };
    const [fileHandle] = await (window as any).showOpenFilePicker(opts);
    const file = await (fileHandle as FileSystemFileHandle)?.getFile();

    if (!file) return;

    setVideoFile(file);
  }, []);

  const handleVideoInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('handleVideoInputChange', e.target.files);
    const file = e.target.files?.[0];

    if (!file) return;

    setVideoFile(file);
  }, []);

  // useEffect(() => {
  //   console.log('videoFile', videoFile);
  // }, [videoFile]);

  // const computeVideoStartDate = useCallback(async () => {
  //   if (!videoFile) return;

  //   console.log('Computing the video start date...');

  //   const worker = new FFprobeWorker();

  //   const fileInfo = await worker.getFileInfo(videoFile);

  //   console.log({ fileInfo });

  // }, [videoFile]);

  // useEffect(() => {
  //   computeVideoStartDate();
  // }, [computeVideoStartDate]);

  // useEffect(() => {
  //   if (!videoFile) return;

  //   console.log('analyzing video file', videoFile);

  //   const getSize = (): number => videoFile.size;

  //   MediaInfo().then(async (mediaInfo) => {
  //     const readChunk = (chunkSize: number, offset: number): Promise<Uint8Array> | Uint8Array =>
  //       new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onload = (event) => {
  //           const result = event.target?.result;
  //           if (event?.target?.error || !result) {
  //             reject(event?.target?.error || 'No result');
  //           } else if (typeof result === 'string') {
  //             reject('Result is a string');
  //           } else {
  //             resolve(new Uint8Array(result));
  //           }
  //         };

  //         reader.readAsArrayBuffer(videoFile.slice(offset, offset + chunkSize));
  //       });

  //     try {
  //       const resultAnalyze = await mediaInfo.analyzeData(getSize, readChunk);
  //       if (resultAnalyze) {
  //         console.log('resultAnalyze', resultAnalyze);
  //         setVideoMetadata(resultAnalyze);
  //       }
  //     } catch (e) {
  //       console.error('analyze error', e);
  //     }

  //     // .then((result) => {
  //     //   output.value = result
  //     // })
  //     // .catch((error) => {
  //     //   output.value = `An error occured:\n${error.stack}`
  //     // })
  //   });
  // }, [videoFile]);

  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processedVideoUrl, setProcessedVideoUrl] = useState<string | null>(null);

  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [orderStreams, setOrderStreams] = useState<(keyof AvailableActivityStreams)[]>([]);
  const [useLapVelocity, setUseLapVelocity] = useState(false);

  const videoInput = useRef<null | HTMLInputElement>(null);

  const generateVideo = async (): Promise<void> => {
    if (!videoFile) return;

    const file = videoFile;

    const data = new FormData();
    data.append('video', file);
    data.append('athleteId', athleteId?.toString() || '');
    data.append('activityId', activityId);
    data.append('streams', [orderStreams[0], orderStreams[1], orderStreams[2]].join(','));
    data.append('useLapVelocity', useLapVelocity ? 'true' : 'false');

    setUploading(true);

    // eslint-disable-next-line no-console
    console.log('Uploading file', file);

    await fetch(`${BASE_URL}/upload-video`, {
      method: 'POST',
      body: data,
    });

    setUploading(false);
    setUploaded(true);
  };

  useEffect(() => {
    if (uploaded) {
      // eslint-disable-next-line no-console
      console.log('uploaded');
      setProcessing(true);
    }
  }, [uploaded]);

  const getVideoStatus = useCallback(async () => {
    const res = await fetch(`${BASE_URL}/get-video-url/${activityId}`, {
      method: 'GET',
    });

    const text = await res.text();

    // eslint-disable-next-line no-console
    console.log('get-video-url', res.status, text);

    if (res.status === 200) {
      setProcessing(false);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = JSON.parse(text);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const videoUrl = json?.video_url;

      const success = json?.success;

      if (!success) {
        setFailed(true);
        setErrorMessage(json?.message);
      }

      if (videoUrl) {
        setProcessedVideoUrl(videoUrl);
      }
    } else if (res.status === 202) {
      // video is being processed
      setProcessing(true);
      setUploaded(true);
    }
  }, [activityId]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (processing) {
      interval = setInterval(getVideoStatus, 2000);
    } else {
      getVideoStatus();
    }

    return () => clearInterval(interval);
  }, [activityId, getVideoStatus, processing]);

  const handleDownloadVideo = useCallback(() => {
    if (!processedVideoUrl) return;

    const videoExtension = processedVideoUrl.split('.').pop();

    window.open(processedVideoUrl, '_blank', `download=${data?.name ?? 'motion-mania-video'}.${videoExtension}`);
  }, [data?.name, processedVideoUrl]);

  useEffect(() => {
    if ((!orderStreams || !orderStreams.length) && availableActivityStreams) {
      const streamsArr = Object.keys(availableActivityStreams)
        .map((key) => key as keyof AvailableActivityStreams)
        .map((key) => {
          return {
            key,
            value: availableActivityStreams[key],
          };
        })
        .filter((stream) => !!stream.value);

      setOrderStreams(streamsArr.map((stream) => stream.key));
    }
  }, [availableActivityStreams, orderStreams]);

  const handleReorderStream = useCallback(
    (event: CustomEvent<ItemReorderEventDetail>) => {
      const items = [...orderStreams];

      event.detail.complete(items);

      setOrderStreams(items);
    },
    [orderStreams]
  );

  const queryAllPermissions = useCallback(async () => {
    const allPermissionsNames: string[] = [
      'geolocation',
      'notifications',
      'push',
      'midi',
      'camera',
      'microphone',
      'background-fetch',
      'background-sync',
      'persistent-storage',
      'ambient-light-sensor',
      'accelerometer',
      'gyroscope',
      'magnetometer',
      'screen-wake-lock',
      'nfc',
      'display-capture',
      'accessibility-events',
      'clipboard-read',
      'clipboard-write',
      'payment-handler',
      'periodic-background-sync',
      'top-level-storage-access',
    ];

    for (const permissionName of allPermissionsNames) {
      try {
        const permissionStatus = await navigator.permissions.query({ name: permissionName as PermissionName });
        console.log('permissionStatus', permissionStatus);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error querying permission', permissionName, e);
      }
    }
  }, []);

  useEffect(() => {
    queryAllPermissions();
  }, [queryAllPermissions]);

  const fileName = useMemo(() => {
    if (processedVideoUrl) {
      const url = new URL(processedVideoUrl);

      return url.pathname.split('/').pop();
    }
  }, [processedVideoUrl]);

  const hasVelocity = useMemo(() => {
    return orderStreams.includes('velocity_smooth') && orderStreams.includes('velocity_split');
  }, [orderStreams]);

  const onToggleChangeLapVelocity = useCallback(() => {
    setUseLapVelocity((checked) => !checked);
  }, []);

  if (!data) {
    return <IonSpinner></IonSpinner>;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{data.name}</IonTitle>
          {uploading || processing ? <IonProgressBar type="indeterminate"></IonProgressBar> : <></>}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{data.name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{data.name}</IonCardTitle>
            <IonCardSubtitle>{data.start_date?.toString()}</IonCardSubtitle>
          </IonCardHeader>
          {/* 
          {
            isIos && iosVersion && iosVersion[0] < 17 ? (
              <IonCardContent>
                <div style={{ textAlign: 'center' }}>
                  <span>Motion Mania does not work on iOS below iOS 17, use your computer instead or upgrade your iOS version</span>
                </div>
              </IonCardContent>
            ) : (
              <></>
            )
          } */}

          {uploading ? (
            <IonSpinner></IonSpinner>
          ) : (
            <>
              {!uploading && !uploaded && !processedVideoUrl && !processing ? (
                <IonCardContent>
                  <div style={{ textAlign: 'center' }}>
                    <input
                      type={'file'}
                      accept={'video/*'}
                      onChange={handleVideoInputChange}
                      ref={videoInput}
                      style={{
                        display: 'none',
                      }}
                    />
                    {/* <IonButton onClick={handleInputBtnClick} expand="block">Select video</IonButton> */}
                    <IonButton onClick={() => videoInput.current?.click()} expand="block">
                      Select video
                    </IonButton>
                  </div>
                </IonCardContent>
              ) : (
                <></>
              )}

              {availableActivityStreams && !processing && (
                <IonCardContent>
                  <IonList>
                    {hasVelocity && (
                      <IonItem>
                        <IonToggle checked={useLapVelocity} onIonChange={(e) => onToggleChangeLapVelocity()}>
                          Use lap velocity
                        </IonToggle>
                      </IonItem>
                    )}
                    <IonReorderGroup disabled={false} onIonItemReorder={handleReorderStream}>
                      {orderStreams.map((streamKey, index) => {
                        return (
                          <IonItem>
                            <IonLabel>
                              {streamKeyToName(streamKey)}
                              {index < 3 ? (
                                <span style={{ marginLeft: '5px' }}>
                                  <View />
                                </span>
                              ) : null}
                            </IonLabel>
                            <IonReorder slot="end" />
                          </IonItem>
                        );
                      })}
                    </IonReorderGroup>
                  </IonList>
                </IonCardContent>
              )}

              {videoFile && !uploaded && !uploading ? (
                <IonButton fill="solid" expand="block" onClick={generateVideo}>
                  Generate video
                </IonButton>
              ) : (
                <></>
              )}
            </>
          )}

          {uploading ? (
            <IonCardContent>
              <div style={{ textAlign: 'center' }}>
                <span>Your video is being uploaded...</span>
              </div>
            </IonCardContent>
          ) : (
            <></>
          )}

          {processing && !failed ? (
            <IonCardContent>
              <div style={{ textAlign: 'center' }}>
                <span>Your video is being processed...</span>
                <br />
                <span>You will receive a notification once ready</span>
              </div>
            </IonCardContent>
          ) : (
            <></>
          )}

          {failed ? (
            <IonCardContent>
              <div style={{ textAlign: 'center' }}>
                <span>Failed to process video</span>
                <br />
                <span>{errorMessage}</span>
              </div>
            </IonCardContent>
          ) : (
            <></>
          )}
        </IonCard>

        {processedVideoUrl ? (
          <IonCard>
            <IonCardContent>
              <video
                autoPlay
                controls
                src={processedVideoUrl}
                loop
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />

              <IonButton expand="block" href={processedVideoUrl} target="_blank" download={fileName}>
                <IonIcon slot="end" icon={downloadOutline}></IonIcon>
                Download
              </IonButton>
            </IonCardContent>
          </IonCard>
        ) : (
          <></>
        )}
      </IonContent>
    </IonPage>
  );
}

function streamKeyToName(key: keyof AvailableActivityStreams): string {
  switch (key) {
    case 'altitude':
      return 'Altitude';
    case 'cadence':
      return 'Cadence';
    case 'distance':
      return 'Distance';
    case 'heartrate':
      return 'Heartrate';
    case 'moving':
      return 'Moving';
    case 'watts':
      return 'Power';
    case 'temp':
      return 'Temperature';
    case 'velocity_smooth':
      return 'Velocity';
    case 'velocity_split':
      return 'Velocity (lap)';
    case 'grade_smooth':
      return 'Grade';
    case 'time':
      return 'Time';
    default:
      return key;
  }
}
